export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },
  getCategories(state) {
    return state.categories;
  },

  getProducts(state) {
    return state.products;
  },

  getDetail(state) {
    return state.detail;
  },

  getProductDetail(state) {
    return state.productDetail;
  }

};
