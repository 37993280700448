import middleware from '../middleware/middleware';

import UserDetail from '@/views/pages/users/detail.vue'
import {permissions} from "@/enums/permissions.enum";

export default [


    {
        path: 'users',
        component: {
            render(c) {
                return c('router-view');
            },
        },
        children: [

            //---------- clients start ------
            {
                path: 'clients',
                name: 'users-clients',
                component: () => import('@/views/pages/users/clients/index.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.readClientsInfo, permissions.readClientsOrders],
                    pagePermissions: {
                        edit: permissions.editClients,
                    }
                },

            },
            {
                path: 'clients/create',
                name: 'users-clients-create',
                component: () => import('@/views/pages/users/clients/create_update.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.addClients],
                },
            },
            {
                path: 'clients/detail/:id',

                component: UserDetail,
                meta: {
                    middleware: [middleware],
                },
                children: [
                    {
                        path: '/',
                        name: 'users-clients-detail',
                        component: () => import('@/views/pages/users/clients/detail.vue'),
                        meta: {
                            type: 'client',
                            middleware: [middleware],
                            permissions: [permissions.readClientsInfo],
                        },

                    },
                    {
                        path: 'orders',
                        name: 'users-clients-orders',
                        component: () => import('@/views/pages/users/common/orders.vue'),
                        meta: {
                            type: 'client',
                            middleware: [middleware],
                            permissions: [permissions.readClientsOrders],
                        },

                    },
                ]
            },
            //---------- clients end ------


            //---------- vendors start ------
            {
                path: 'vendors',
                name: 'users-vendors',
                component: () => import('@/views/pages/users/vendors/index.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                    pagePermissions: {
                        create: permissions.addCompanies,
                        edit: permissions.editCompanies,
                    }
                },

            },
            {
                path: 'vendors/create',
                name: 'users-vendors-create',
                component: () => import('@/views/pages/users/vendors/create_update.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.addCompanies],
                },
            },


            {
                path: 'vendors/update/:id',
                name: 'users-vendors-update',
                component: () => import('@/views/pages/users/vendors/create_update.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.editCompanies],

                },
            },

            {
                path: 'vendors/detail/:id',

                component: UserDetail,
                meta: {
                    middleware: [middleware],
                },
                children: [
                    {
                        path: '/',
                        name: 'users-vendors-detail',
                        component: () => import('@/views/pages/users/vendors/detail.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesInfo],

                        },

                    },
                    {
                        path: 'orders',
                        name: 'users-vendors-orders',
                        component: () => import('@/views/pages/users/common/orders.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesOrders],
                        },

                    },
                    {
                        path: 'categories',
                        name: 'users-vendors-categories',
                        component: () => import('@/views/pages/users/vendors/details/categories/index.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesCategories],
                            pagePermissions: {
                                create: permissions.addCompaniesCategories,
                                edit: permissions.editCompaniesCategories,
                                read: permissions.readCompaniesCategories,
                                delete: permissions.deleteCompaniesCategories
                            }
                        },
                    },

                    //todo:: add permissions
                    {
                        path: 'workdays',
                        name: 'users-vendors-workdays',
                        component: () => import('@/views/pages/users/vendors/details/workdays/index.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesCategories],
                            pagePermissions: {
                                create: permissions.addCompaniesCategories,
                                edit: permissions.editCompaniesCategories,
                                read: permissions.readCompaniesCategories,
                                delete: permissions.deleteCompaniesCategories
                            }
                        },
                    },
                    {
                        path: 'products',
                        name: 'users-vendors-products',
                        component: () => import('@/views/pages/users/vendors/details/products/index.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesProducts],
                            pagePermissions: {
                                create: permissions.addCompaniesProducts,
                                edit: permissions.editCompaniesProducts,
                                read: permissions.readCompaniesProducts,
                                delete: permissions.deleteCompaniesProducts
                            }
                        },
                    },
                    {
                        path: 'products/create',
                        name: 'users-vendors-products-create',
                        component: () => import('@/views/pages/users/vendors/details/products/create_update.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.addCompaniesProducts],
                        },
                    },
                    {
                        path: 'products/update/:prod_id',
                        name: 'users-vendors-products-update',
                        component: () => import('@/views/pages/users/vendors/details/products/create_update.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.editCompaniesProducts],
                        },
                    },


                    {
                        path: 'packages',
                        name: 'users-vendors-packages',
                        component: () => import('@/views/pages/users/vendors/details/packages/index.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesProducts],
                            pagePermissions: {
                                create: permissions.addCompaniesProducts,
                                edit: permissions.editCompaniesProducts,
                                read: permissions.readCompaniesProducts,
                                delete: permissions.deleteCompaniesProducts
                            }
                        },
                    },
                    {
                        path: 'packages/create',
                        name: 'users-vendors-packages-create',
                        component: () => import('@/views/pages/users/vendors/details/packages/create_update.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.addCompaniesProducts],
                        },
                    },
                    {
                        path: 'packages/update/:prod_id',
                        name: 'users-vendors-packages-update',
                        component: () => import('@/views/pages/users/vendors/details/packages/create_update.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.editCompaniesProducts],
                        },
                    },

                    {
                        path: 'financials',
                        name: 'users-vendors-financials',
                        component: () => import('@/views/pages/users/common/financials/index.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesFinancials],
                        },
                    },

                    {
                        path: 'reviews',
                        name: 'users-vendors-reviews',
                        component: () => import('@/views/pages/users/common/reviews/index.vue'),
                        meta: {
                            type: 'vendor',
                            middleware: [middleware],
                            permissions: [permissions.readCompaniesReviews],
                        },
                    },
                ]
            },

            //---------- vendors end ------


            // ----------------------------------

            //---------- drivers start ------
            {
                path: 'drivers',
                name: 'users-drivers',
                component: () => import('@/views/pages/users/drivers/index.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.readDriversInfo, permissions.readDriversFinancials, permissions.readDriversOrders, permissions.readDriversReviews],
                    pagePermissions: {
                        create: permissions.addDrivers,
                        edit: permissions.editDrivers,
                    }
                },

            },
            {
                path: 'drivers/create',
                name: 'users-drivers-create',
                component: () => import('@/views/pages/users/drivers/create_update.vue'),
                meta: {
                    middleware: [middleware],
                    permissions: [permissions.addDrivers],
                },
            },
            {
                path: 'drivers/update/:id',
                name: 'users-drivers-update',
                component: () => import('@/views/pages/users/drivers/create_update.vue'),
                meta: {
                    middleware: [middleware],

                    permissions: [permissions.editDrivers],
                },
            },


            {
                path: 'drivers/detail/:id',

                component: UserDetail,
                meta: {
                    middleware: [middleware],
                },

                children: [
                    {
                        path: '/',
                        name: 'users-drivers-detail',
                        component: () => import('@/views/pages/users/drivers/detail.vue'),
                        meta: {
                            type: 'driver',
                            middleware: [middleware],
                            permissions: [permissions.readDriversInfo],
                        },

                    },
                    {
                        path: 'orders',
                        name: 'users-drivers-orders',
                        component: () => import('@/views/pages/users/common/orders.vue'),
                        meta: {
                            type: 'driver',
                            middleware: [middleware],
                            permissions: [permissions.readDriversOrders],
                        },

                    },
                    {
                        path: 'financials',
                        name: 'users-drivers-financials',
                        component: () => import('@/views/pages/users/common/financials/index.vue'),
                        meta: {
                            type: 'driver',
                            middleware: [middleware],
                            permissions: [permissions.readDriversFinancials],
                        },

                    },
                    {
                        path: 'reviews',
                        name: 'users-drivers-reviews',
                        component: () => import('@/views/pages/users/common/reviews/index.vue'),
                        meta: {
                            type: 'driver',
                            middleware: [middleware],
                            permissions: [permissions.readDriversReviews],
                        },
                    },
                ]
            },


            //---------- drivers end ------

        ],
    },


];
