export default {
  setData: (state, data) => {
    state.data = data;
  },
  setVendors: (state, data) => {
    state.vendors = data;
  },
  setVendorProducts: (state, data) => {
    state.vendorProducts = data;
  },
};
