import middleware from '../middleware/middleware';
import { permissions } from '@/enums/permissions.enum';


export default [


  {
    path: 'settings',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('@/views/pages/settings/settings.vue'),
        meta: {
          middleware: [middleware]

        }
      },

      {
        path: 'system-notifications',
        name: 'system-notifications',
        component: () => import('@/views/pages/settings/notifications/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readAdminUsers],
          pagePermissions: {
            create: permissions.sendNotifications_settings,
            read: permissions.sendNotifications_settings
          }
        }
      },
      {
        path: 'system-notifications-create',
        name: 'system-notifications-create',
        component: () => import('@/views/pages/settings/notifications/send_notifications.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.sendNotifications_settings]
        }
      },
      {
        path: 'system-notifications-update/:id',
        name: 'system-notifications-update',
        component: () => import('@/views/pages/settings/notifications/update_notifications.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.sendNotifications_settings],
        },
      },
      {
        path: 'system-notifications-detail/:id',
        name: 'system-notifications-detail',
        component: () => import('@/views/pages/settings/notifications/detail.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.sendNotifications_settings]
        }
      },
      // {
      //     path: 'general',
      //     name: 'settings-general',
      //     component: () => import('@/views/pages/settings/general.vue'),
      //     meta: {
      //         middleware: [middleware],
      //         permissions: [permissions.general_settings]
      //
      //     }
      // },
      {
        path: 'services-fee',
        name: 'settings-services-fee',
        component: () => import('@/views/pages/settings/service_fees/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readServiceFees],
          pagePermissions: {
            create: permissions.addServiceFees,
            edit: permissions.editServiceFees,
            read: permissions.readServiceFees,
            delete: permissions.deleteServiceFees
          }
        }
      },
      {
        path: 'vehicles',
        name: 'settings-vehicles',
        component: () => import('@/views/pages/settings/vehicles/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readVehicleTypes],
          pagePermissions: {
            create: permissions.addVehicleTypes,
            edit: permissions.editVehicleTypes,
            read: permissions.readVehicleTypes,
            delete: permissions.deleteVehicleTypes
          }
        }
      },
      {
        path: 'classifications',
        name: 'settings-classifications',
        component: () => import('@/views/pages/settings/classifications/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readClassifications],
          pagePermissions: {
            create: permissions.addClassifications,
            edit: permissions.editClassifications,
            read: permissions.readClassifications,
            delete: permissions.deleteClassifications
          }
        }
      },
      {
        path: 'system-logs',
        name: 'settings-logs',
        component: () => import('@/views/pages/settings/logs/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readLogs]
        }
      },
      {
        path: 'app-messages',
        name: 'settings-app-messages',
        component: () => import('@/views/pages/settings/app_messages/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readAppMessages],
          pagePermissions: {
            create: permissions.addAppMessages,
            edit: permissions.editAppMessages,
            read: permissions.readAppMessages,
            delete: permissions.deleteAppMessages
          }
        }
      },
      {
        path: 'payments',
        name: 'settings-payments',
        component: () => import('@/views/pages/settings/payment_methods/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readPaymentGetaways_settings],
          pagePermissions: {
            edit: permissions.updatePaymentGetaways_settings,
            read: permissions.readPaymentGetaways_settings
          }
        }
      },
      {
        path: 'services-detail',
        name: 'settings-services-detail',
        component: () => import('@/views/pages/settings/services_detail/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readServiceDetail],
          pagePermissions: {
            edit: permissions.editServiceDetail,
            read: permissions.readServiceDetail
          }
        }
      },
      {
        path: 'order-types',
        name: 'settings-order-types',
        component: () => import('@/views/pages/settings/order_types/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readOrderTypes],
          pagePermissions: {
            create: permissions.addOrderTypes,
            edit: permissions.editOrderTypes,
            read: permissions.readOrderTypes,
            delete: permissions.deleteOrderTypes
          }
        }
      },
      {
        path: 'contact',
        name: 'settings-contact',
        component: () => import('@/views/pages/settings/profile/profile.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.contacts_settings, permissions.boundary_settings],
          pagePermissions: {
            contact: permissions.contacts_settings,
            boundary: permissions.boundary_settings,
          }
        }
      },
      {
        path: 'work_days',
        name: 'work_days',
        component: () => import('@/views/pages/settings/work_days/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readWorkDays],
          pagePermissions: {
            create: permissions.addWorkDays,
            edit: permissions.editWorkDays,
            read: permissions.readWorkDays,
            delete: permissions.deleteWorkDays
          }
        }
      },
      {
        path: 'system-users',
        name: 'system-users',
        component: () => import('@/views/pages/settings/system_users/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readAdminUsers],
          pagePermissions: {
            create: permissions.addAdminUsers,
            edit: permissions.editAdminUsers,
            read: permissions.readAdminUsers,
            delete: permissions.deleteAdminUsers
          }
        }
      },
      {
        path: 'system-users/create',
        name: 'system-users-create',
        component: () => import('@/views/pages/settings/system_users/create_update.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.addAdminUsers]
        }
      },
      {
        path: 'system-users/update/:id',
        name: 'system-users-update',
        component: () => import('@/views/pages/settings/system_users/create_update.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.editAdminUsers]
        }
      },

      {
        path: 'system-roles',
        name: 'system-roles',
        component: () => import('@/views/pages/settings/system_roles/index.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.readRoles],
          pagePermissions: {
            create: permissions.addRoles,
            edit: permissions.editRoles,
            read: permissions.readRoles,
            delete: permissions.deleteRoles
          }
        }
      },
      {
        path: 'system-roles/create',
        name: 'system-roles-create',
        component: () => import('@/views/pages/settings/system_roles/create_update.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.addRoles]
        }
      },
      {
        path: 'system-roles/update/:id',
        name: 'system-roles-update',
        component: () => import('@/views/pages/settings/system_roles/create_update.vue'),
        meta: {
          middleware: [middleware],
          permissions: [permissions.editRoles]
        }
      },


      {
        path: 'content/policy',
        name: 'settings-page-content',
        component: () => import('@/views/pages/settings/Pages_Content/pageContent.vue'),
        meta: {
          page: 'privacy',
          middleware: [middleware],
          permissions: [permissions.pages_settings]
        }
      },

      {
        path: 'content/about',
        name: 'settings-page-content-about',
        component: () => import('@/views/pages/settings/Pages_Content/pageContent.vue'),
        meta: {
          page: 'about',
          middleware: [middleware],
          permissions: [permissions.pages_settings]
        }
      }
    ]
  }


];
