export default {
  getBasic(state) {
    return state.basic;
  },
  getPermissions(state) {
    return state.permissions;
  },
  getFull(state) {
    return state.full;
  },
};
