/**
 * Vuex actions for managing user details.
 * @module store/admin/users/detail/actions
 */

export default {

  /**
   * Fetch user's orders.
   * @async
   * @function orders
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user whose orders are to be fetched.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async orders({ commit }, data) {
    commit('unsetData');
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`users/${data.type}/detail/${data.id}/orders${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch user's reviews.
   * @async
   * @function reviews
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user whose reviews are to be fetched.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async reviews({ commit }, data) {
    commit('unsetData');
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`users/${data.type}/detail/${data.id}/reviews${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setReviews', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch user's financials.
   * @async
   * @function financials
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user whose financials are to be fetched.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async financials({ commit }, data) {
    commit('unsetData');
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`users/${data.type}/detail/${data.id}/financials${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setFinancials', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch user's info.
   * @async
   * @function info
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user whose info is to be fetched.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async info({ commit }, data) {
    commit('loadingStart', null, { root: true });

    return await this._vm.$http
      .get(`users/${data.type}/detail/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setUserInfo', respo.data.data);

          commit('loadingFinish', null, { root: true });
          return respo.data.data;
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Unset user's detail.
   * @async
   * @function unsetDetail
   * @param {Object} context - Vuex action context.
   */
  async unsetDetail({ commit }) {
    commit('unsetDetail');
  }


};
