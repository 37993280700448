export default {
  getData(state) {
    return state.data;
  },
  getVendors(state) {
    return state.vendors;
  },
  getVendorProducts(state) {
    return state.vendorProducts;
  },

};
