export default {
  /**
   * Fetches contact settings from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async get({ commit }) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`contact-settings`)
      .then((response) => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates contact settings on the server.
   * If the request is successful, it shows a success message and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`contact-settings/update`, data)
      .then((response) => {
        if (response.status === 200) {


          commit('successMessage', 'save', { root: true });
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {

        commit('failMessage', 'save', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Updates boundary settings on the server.
   * If the request is successful, it shows a success message and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async updateBoundary({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`boundary-settings/update`, data)
      .then((response) => {
        if (response.status === 200) {


          commit('successMessage', 'save', { root: true });
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {

        commit('failMessage', 'save', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
