export default {
    setData: (state, data) => {
        state.data = data;
    },

    setVendorsList: (state, data) => {
        state.vendorsList = data;
    },
    setServicesList: (state, data) => {
        state.servicesList = data;
    },

    setTopClientsVendor: (state, data) => {
        state.topClientsVendor = data;
    },

    unsetVendors: (state) => {
        state.data.vendors = {
            "vendors": [],
            "total": 0
        }
    },
    unsetDrivers: (state) => {
        console.log('unsetDrivers')
        state.data.drivers = {
            "drivers": [],
            "total": 0
        }
    },


};
