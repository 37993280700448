export default {
  permissions: [],
  data: [],
  detail:{},
  total: {
    totalItems: 5,
    totalPages: 5,
  },

};
