import store from '@/store/index';
import Vue from 'vue';

/**
 * Middleware function to handle user authentication and redirection.
 * If the user is logged in, they are redirected to the dashboard.
 * If the user is not logged in, they are redirected to the login page.
 *
 * @param {Object} param0 - The context object.
 * @param {Object} param0.to - The route the user is navigating to.
 * @param {Function} param0.next - The function to resolve the navigation.
 */
export default function middleware({to, next}) {
    // Define the routes that require the user to be logged in
    let loginRoutes = [
        'login',
        'signup',
        'forgot-password',
        'reset-password',
        'validate-email',
        'verify-email',
    ];

    // Get the user's login data from cookies
    let loginData = Vue.$cookies.get('login-data', {parseJSON: true});

    // If the user is logged in
    if (loginData) {
        // If the user has chosen to be remembered
        if (loginData.remember_me) {
          const date = Date.now();
          if (loginData.exp_date <= date) {
                store.dispatch('auth/refreshToken', loginData.refresh_token).then(() => {
                    // If the user is trying to access a login route, redirect them to the dashboard
                    if (loginRoutes.includes(to.name))
                        return next({name: 'dashboard', params: {lang: to.params.lang}});
                    else return next();
                });
            } else {
                // If the user's session has not expired, and they are trying to access a login route, redirect them to the dashboard
                if (loginRoutes.includes(to.name))
                    return next({name: 'dashboard', params: {lang: to.params.lang}});
                else return next();
            }
        } else {
            // If the user has not chosen to be remembered, and they are trying to access a login route, redirect them to the dashboard
            if (loginRoutes.includes(to.name))
                return next({name: 'dashboard', params: {lang: to.params.lang}});
            else return next();
        }
    } else {
        // If the user is not logged in, and they are trying to access a non-login route, redirect them to the login page
        if (!loginRoutes.includes(to.name))
            return next({name: 'login', params: {lang: to.params.lang}});
        else return next();
    }
}
