export default {
    categories: [],
    data: [],
    total: {
        totalItems: 5,
        totalPages: 5,
    },

    detail: {
        sizes: [],
        types: [],
        addons: [],
    },

};
