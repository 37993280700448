import middleware from '../middleware/middleware';

const meta = {
    middleware: middleware,
    layout: 'auth',
    resource: 'Auth',
    redirectIfLoggedIn: true,
};

export default [

    {
        path: 'login',
        name: 'login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            ...meta,
            authImage: 'login-bg',
        },
    },
    {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            ...meta,
            authImage: '',
        },
    },
    {
        path: 'reset-password/:token',
        name: 'reset-password',
        component: () => import('@/views/pages/authentication/ResetPassword.vue'),
        meta: {
            ...meta,
            authImage: 'login-bg',
        },
    },
    {
        path: 'validate-email',
        name: 'validate-email',
        component: () => import('@/views/pages/authentication/ValidateEmail.vue'),
        meta: {
            ...meta,
            authImage: 'login-bg',
        },
    },



];
