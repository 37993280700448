export default {
    setUsers(state, users) {
        state.users = users;
    },
    setFilters(state, filters) {
        state.filters = filters;
    },

    setStatement(state, data) {
        state.statement.user = data.user;
        state.statement.accounting = data.accounting.data;
        state.statement.total = {
            totalItems: data.accounting.total,
            totalPages: data.accounting.last_page,
        };
    },

    resetStatement(state) {
        state.statement = {
            user: null,
            accounting: [],
            total: {
                totalItems: 0,
                totalPages: 0,
            },
        }

    }
}
