export default {
    data: {
        "total_customers": 0,
        "total_vendors": 0,
        "total_drivers": 0,
        "total_orders": 0,
        "our_profit_orders": 0,
        "total_orders_sales": 0,
        "our_profit_delivery": 0,
        "total_delivery": 0,
        "users": {
            "males": 0,
            "females": 0,
            "uncompleted": 0,
        },
        "orders_data": {
            "translate": true,
            "periods": [],
            "orders": [],
        },
        "popular_vendors": [],
        "total_popular_vendors": 104,
        "sales_data": {
            "translate": true,
            "periods": [],
            "sales": [],
            "profits": []
        },
        "pending_orders": []
    },
};
