export default {
  setData: (state, data) => {
    state.data = data.content;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },


  deleteItem: (state, id) => {
    let data = state.data;
    state.data = data.filter((item) => item.id !== id)
  },


  setApplyFor: (state, data) => {
    state.applyFor = data;
  },


};
