export default {
  /**
   * Fetches featured data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`featured${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the status of a featured item on the server and commits the updated featured data to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`featured/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new featured item on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`featured/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates an existing featured item on the server.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`featured/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Deletes a featured item from the server and removes it from the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`featured/delete/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches vendors data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} cat_id - The ID of the category to fetch vendors for.
   */
  async getVendors({ commit }, cat_id) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`featured/dropdown/vendors/${cat_id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setVendors', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches categories data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getCategories({ commit }) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`featured/dropdown/categories`)
      .then(respo => {
        if (respo.status === 200) {
          console.log(respo.data.data);
          commit('setCategories', respo.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
