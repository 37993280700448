export default {
  getSocials(state) {
    return state.socials;
  },
  getContacts(state) {
    return state.contacts;
  },
  getBoundary(state) {
    return state.boundary;
  },

};
