/**
 * Vuex actions for managing statements.
 * @module store/statement/actions
 */
export default {
  /**
   * Fetch users based on type.
   * @async
   * @function getUsers
   * @param {Object} context - Vuex action context.
   * @param {string} type - Type of users to fetch.
   */
  async getUsers({ commit }, type) {
    commit('setUsers', []);

    commit('resetStatement');
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`statement/${type}`)
      .then((response) => {
        if (response.status === 200) {

          commit('setUsers', response.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch filters based on type.
   * @async
   * @function getFilters
   * @param {Object} context - Vuex action context.
   * @param {string} type - Type of filters to fetch.
   */
  async getFilters({ commit }, type) {
    commit('setFilters', []);

    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`statement/filters/${type}`)
      .then((response) => {
        if (response.status === 200) {
          commit('setFilters', response.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch statement based on filters.
   * @async
   * @function getStatement
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for fetching statement.
   */
  async getStatement({ commit }, data) {
    // commit('resetStatement');

    commit('loadingStart', null, { root: true });
    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`statement/data${filters}`)
      .then((response) => {
        if (response.status === 200) {

          console.log(response.data.data);
          commit('setStatement', response.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });

  },

  /**
   * Export statement to Excel.
   * @async
   * @function exportExcel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for exporting statement.
   */
  async exportExcel({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`statement/export-excel${filters}`, { responseType: 'blob' })
      .then(respo => {
        if (respo.status === 200) {
          // commit('setData', respo.data.data);
          const url = window.URL.createObjectURL(new Blob([respo.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'statement.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
