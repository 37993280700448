import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import reviews from './reviews';
import auth from './auth';
import utilities from './utilities';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import vendor from './vendor';
import admin from './admin';
import profile from './profile';
import chat from './chat';
import statement from './statement';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        vendor,
        chat,
        statement,
        profile,
        admin,
        app,
        appConfig,
        verticalMenu,
        auth,
        reviews,
        utilities,
    },
    strict: process.env.DEV,
});
