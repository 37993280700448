export default {
  setData: (state, data) => {
    state.data = data.content;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },

  unsetVehicles: (state) => {
    state.data =[];
  },


  updateData: (state, data) => {
    let temp = state.data;
    state.data = temp.map((item) => item.id === data.id ? {...item, status: data.status} : item)
  },

  deleteItem: (state, id) => {
    let data = state.data;
    state.data = data.filter((item) => item.id !== id)
  },
  setDetail: (state, data) => {
    state.detail = data;
  },
};
