// import axios from '@/plugins/axios';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getFirestore,
  increment,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  where
} from 'firebase/firestore';

import moment from 'moment';
import { MessageTypeEnum } from '@/enums/messageType.enum';

/**
 * Vuex actions for managing chat.
 * @module store/chat/actions
 */

const collectionName = process.env.VUE_APP_CONVERSATION_COLLECTION;
const unreadMessageCountcollectionName = process.env.VUE_APP_UNREAD_MESSAGE_COUNT_CONVERSATION_COLLECTION;

/**
 * Get room info based on members and wanted section.
 * @function getRoomInfo
 * @param {Array} members - Array of members.
 * @param {string} wantedSection - Wanted section.
 * @returns {string} Room info.
 */
function getRoomInfo(members, wantedSection) {
  if (members) {
    let room = members.find((member) => {
      return member.type != 1;//admin
    });

    if (room) {
      if (wantedSection === 'name') {
        if (room.name) {
          return room.name;
        } else {
          return 'unknown user';
        }
      }
      if (wantedSection === 'avatar') {
        if (room.avatar) {
          return room.avatar;
        } else {
          return 'unknown user';
        }
      }
    }
  }
  return '';
}

/**
 * Get user info based on members and user id.
 * @function getUserInfo
 * @param {Array} members - Array of members.
 * @param {string} userId - User id.
 * @param {string} wantedSection - Wanted section.
 * @returns {string} User info.
 */
function getUserInfo(members, userId, wantedSection) {
  if (members) {
    let room = members.find((member) => {
      return member.id == userId;
    });

    if (room) {
      if (wantedSection === 'name') {
        if (room.name) {
          return room.name;
        } else {
          return 'unknown user';
        }
      }
      if (wantedSection === 'avatar') {
        if (room.avatar) {
          return room.avatar;
        } else {
          return 'unknown user';
        }
      }
    }
  }

  return '';
}

/**
 * Convert Arabic numerals to English.
 * @function arabicToEnglish
 * @param {string} s - String containing Arabic numerals.
 * @returns {string} String with Arabic numerals converted to English.
 */
function arabicToEnglish(s) {
  return s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
}


export default {
  /**
   * Get chat rooms.
   * @async
   * @function getRooms
   * @param {Object} context - Vuex action context.
   */
  async getRooms({ commit, getters, rootGetters }) {

    //then get the permissions and check if the user has the chat permission
    let permissions = rootGetters['profile/getPermissions'];
    if (!permissions.includes('chat')) {
      return false;
    }

    const db = getFirestore();

    const conversationsRef = query(
      collection(db, collectionName),
      where('isConversationWithAdmin', '==', true)
    );

    // establish a listener for the query using querySnapshot.
    onSnapshot(conversationsRef, querySnapshot => {
      let roomsIds = [];
      let rooms = [];

      querySnapshot.forEach(doc => {

        let docData = doc.data();
        roomsIds.push(docData.id);


        rooms.push({
          roomId: docData.id,
          index: new Date(arabicToEnglish(docData.lastMessageDate)),
          roomName: getRoomInfo(docData.members, 'name'),
          avatar: getRoomInfo(docData.members, 'avatar'),
          unreadCount: docData.unreadMessagesMemberB,
          startingAdminId: docData.startingAdminId,
          closedById: docData.closedById,
          users: docData.members ? docData.members.map((d) => {
            return {
              _id: d.id,
              avatar: d.avatar,
              username: d.name

            };
          }) : [],
          lastMessage: {
            content: docData.lastMessage,

            timestamp: new Intl.DateTimeFormat('default', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric'
            }).format(new Date(arabicToEnglish(docData.lastMessageDate)))
          }
        });
      });
      //check if there is a new add to the rooms array
      if (rooms.length > 0 && !getters['getRoomsForFirstTime']) {
        //check if there is a new rooms

        let newRooms = rooms.filter((room) => {
          //check if the room is not in the rooms array or has a new message and unreadMessagesMemberB > 0
          return !getters['getRooms'].some((_room) => _room.roomId === room.roomId)
            || (getters['getRooms'].some((_room) => _room.roomId === room.roomId && _room.unreadCount !== room.unreadCount && room.unreadCount > 0));

        });

        commit('setNewRoom', newRooms);


      }
      commit('setRoomIds', roomsIds);
      commit('setRooms', rooms);
      commit('setRoomsForFirstTime', false);

    });

  },

  /**
   * Get unread messages count.
   * @async
   * @function getUnreadMessagesCount
   * @param {Object} context - Vuex action context.
   * @param {string} userId - User id.
   */
  async getUnreadMessagesCount({ commit }, userId) {
    const db = await getFirestore();
    const unreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, 'admin');
    const snapshot = await getDoc(unreadMessageCountDoc);
    if (snapshot.exists()) {
      commit('setUnreadMessagesCount', snapshot.data().countUnreadMessages);
    }
  },

  /**
   * Send a message.
   * @async
   * @function sendMessage
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the message to send.
   */
  async sendMessage({ commit }, data) {
    moment.locale('en');


    const db = await getFirestore();
    const roomDoc = doc(db, collectionName, data.roomId);

    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(roomDoc);
      if (!sfDoc.exists()) {
        throw 'Document does not exist!';
      }

      const startAdminId = sfDoc.data().startingAdminId;
      const closedById = sfDoc.data().closedById;
      const membersId = sfDoc.data().membersId;
      const user = sfDoc.data().members.find((member) => {
        return member.type != 1;//userTypes.admin;
      });
      const unreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, user.id);
      const adminUnreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, 'admin');


      if (closedById) {
        commit('failMessage', 'chat_already_closed', { root: true });
        return false;
      }
      if (startAdminId == null) {

        let updateData = {
          startingAdminId: data.user.id,
          members: arrayUnion(data.user),
          membersId: arrayUnion(data.user.id),
          lastMessage: data.message.content,
          lastMessageDate: moment().format(),
          unreadMessagesMemberA: increment(1)

        };

        if (data.message.type !== MessageTypeEnum.text) {
          updateData['lastMessage'] = data.message.type;
        }

        transaction.update(roomDoc, updateData);


        await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
          body: data.message.content,
          created_at: moment().format(),
          sender_id: data.user.id,
          type: data.message.type
        });  // unreadMessages for user

        //increase countUnreadMessages for user
        await transaction.update(unreadMessageCountDoc, {
          countUnreadMessages: increment(1)
        });

        //decrease countUnreadMessages for admin by the current room unreadMessagesMemberB
        await transaction.update(adminUnreadMessageCountDoc, {
          countUnreadMessages: increment(-sfDoc.data().unreadMessagesMemberB)
        });
        //check if the countUnreadMessages is less than 0
        const adminUnreadMessageCountDocSnapshot = await getDoc(adminUnreadMessageCountDoc);
        if (adminUnreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
          await transaction.update(adminUnreadMessageCountDoc, {
            countUnreadMessages: 0
          });
        }
        //set unreadMessagesMemberB to 0
        await transaction.update(roomDoc, {
          unreadMessagesMemberB: 0
        });


        return startAdminId;
      } else {
        if (membersId.includes(data.user.id)) {
          let updateData = {
            unreadMessagesMemberA: increment(1),
            lastMessage: data.message.content,
            lastMessageDate: moment().format()
          };

          if (data.message.type !== MessageTypeEnum.text) {
            updateData['lastMessage'] = data.message.type;
          }

          transaction.update(roomDoc, updateData);

          await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
            body: data.message.content,
            created_at: moment().format(),
            sender_id: data.user.id,
            type: data.message.type
          });

          //increase countUnreadMessages for user
          await transaction.update(unreadMessageCountDoc, {
            countUnreadMessages: increment(1)
          });


          //decrease countUnreadMessages for admin by the current room unreadMessagesMemberB
          await transaction.update(adminUnreadMessageCountDoc, {
            countUnreadMessages: increment(-sfDoc.data().unreadMessagesMemberB)
          });
          //check if the countUnreadMessages is less than 0
          const adminUnreadMessageCountDocSnapshot = await getDoc(adminUnreadMessageCountDoc);
          if (adminUnreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
            await transaction.update(adminUnreadMessageCountDoc, {
              countUnreadMessages: 0
            });
          }
          //set unreadMessagesMemberB to 0
          await transaction.update(roomDoc, {
            unreadMessagesMemberB: 0
          });
          return true;
        }
        commit('failMessage', 'chat_user', { root: true });

        return false;
      }
    });


  },

  /**
   * Add a user to a chat room.
   * @async
   * @function addUserToRoom
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user to add.
   */
  async addUserToRoom({ commit }, data) {
    commit('loadingStart', null, { root: true });
    moment.locale('en');


    const db = await getFirestore();
    const roomDoc = doc(db, collectionName, data.roomId);

    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(roomDoc);
      if (!sfDoc.exists()) {
        throw 'Document does not exist!';
      }

      const startAdminId = sfDoc.data().startingAdminId;
      const membersId = sfDoc.data().membersId;
      const closedById = sfDoc.data().closedById;

      if (closedById) {
        commit('failMessage', 'chat_already_closed', { root: true });
        return false;
      }
      if (!membersId.includes(data.user.id)) {
        var _data = {
          members: arrayUnion(data.user),
          membersId: arrayUnion(data.user.id)
        };
        if (startAdminId == null) {
          _data['startAdminId'] = data.sender_id;
        }
        transaction.update(roomDoc, _data);

        await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
          body: data.message.content,
          created_at: moment().format(),
          sender_id: data.sender_id,
          type: data.message.type
        });

        commit('successMessage', 'add_to_chat', { root: true });
        return startAdminId;
      }
      commit('failMessage', 'add_to_chat', { root: true });
      return false;
    });

    commit('loadingFinish', null, { root: true });

  },

  /**
   * Close a chat room.
   * @async
   * @function closeRoom
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the room to close.
   */
  async closeRoom({ commit }, data) {
    commit('loadingStart', null, { root: true });
    moment.locale('en');


    const db = await getFirestore();
    const roomDoc = doc(db, collectionName, data.roomId);

    let val = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(roomDoc);
      if (!sfDoc.exists()) {
        throw 'Document does not exist!';
      }

      const startAdminId = sfDoc.data().startingAdminId;
      const closedById = sfDoc.data().closedById;
      const membersId = sfDoc.data().membersId;
      if (closedById == null) {
        if (membersId.includes(data.sender_id)) {
          let _data = {
            closedById: data.sender_id,
            closedDate: moment().format()
          };
          if (startAdminId == null) {
            _data['startAdminId'] = data.sender_id;
          }
          transaction.update(roomDoc, _data);

          await addDoc(collection(db, collectionName, data.roomId, 'messages'), {
            body: data.message.content,
            created_at: moment().format(),
            sender_id: data.sender_id,
            type: data.message.type
          });
          commit('successMessage', 'close_chat', { root: true });
          return true;
        }

        commit('failMessage', 'close_chat_not_member', { root: true });
        return startAdminId;
      }

      commit('failMessage', 'close_chat', { root: true });
      return false;
    });

    commit('loadingFinish', null, { root: true });
    return val;

  },

  /**
   * Get messages from a chat room.
   * @async
   * @function getRoomMessages
   * @param {Object} context - Vuex action context.
   * @param {string} roomId - Room id.
   */
  async getRoomMessages({ commit, getters }, roomId) {

    commit('clearRoomMessages');
    const db = await getFirestore();
    const conversations = doc(db, collectionName, roomId);
    const snapshot = await getDoc(conversations);
    const members = snapshot.data().members;

    if (getters['getSubscribe']) {
      getters['getSubscribe']();
    }

    if (snapshot.exists()) {
      const messageRef = query(
        collection(db, collectionName, roomId, 'messages'),
        orderBy('created_at', 'asc')
      );
      // establish a listener for the query using querySnapshot.
      let subscribe = onSnapshot(messageRef, querySnapshot => {


        let messages = [];
        querySnapshot.forEach(doc => {
          const msg = doc.data();

          const message = {
            _id: doc.id,
            content: msg.body,
            type: msg.type,
            senderId: msg.sender_id,
            username: getUserInfo(members, msg.sender_id, 'name'),
            avatar: getUserInfo(members, msg.sender_id, 'avatar'),
            timestamp: new Intl.DateTimeFormat('default', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric'
            }).format(new Date(arabicToEnglish(msg.created_at))),
            date: new Date(arabicToEnglish(msg.created_at)).toDateString()
          };
          if (msg.type === MessageTypeEnum.image) {
            message['files'] = [
              {
                type: 'png',
                url: msg.body,
                preview: msg.body
              }
            ];
            message['content'] = '';
          }


          // commit('pushRoomMessages', message);
          messages.push(message);
        });


        //mark as read
        const adminUnreadMessageCountDoc = doc(db, unreadMessageCountcollectionName, 'admin');

        runTransaction(db, async (transaction) => {
          const sfDoc = await transaction.get(conversations);
          if (!sfDoc.exists()) {
            throw 'Document does not exist!';
          }


          //decrease countUnreadMessages for admin by the current room unreadMessagesMemberB
          await transaction.update(adminUnreadMessageCountDoc, {
            countUnreadMessages: increment(-sfDoc.data().unreadMessagesMemberB)
          });

          //check if the countUnreadMessages is less than 0
          const adminUnreadMessageCountDocSnapshot = await getDoc(adminUnreadMessageCountDoc);
          if (adminUnreadMessageCountDocSnapshot.data().countUnreadMessages < 0) {
            await transaction.update(adminUnreadMessageCountDoc, {
              countUnreadMessages: 0
            });
          }
          //set unreadMessagesMemberB to 0
          await transaction.update(conversations, {
            unreadMessagesMemberB: 0
          });


        });

        commit('setRoomMessages', messages);
      });

      commit('subscribe', subscribe);


    }
  }
};
