/**
 * Vuex actions for managing vendor orders.
 * @module store/vendor/orders/actions
 */
export default {
  /**
   * Fetch orders based on filters.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for fetching orders.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);
    await this._vm.$http
      .get(`orders${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch order details.
   * @async
   * @function detail
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the order.
   */
  async detail({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/${id}?order_type=restaurants`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDetail', respo.data.data);
          commit('setProducts', []);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch cancel reasons.
   * @async
   * @function cancelReasons
   * @param {Object} context - Vuex action context.
   */
  async cancelReasons({ commit }) {

    await this._vm.$http
      .get(`orders/info/cancel-reasons`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setReasons', respo.data.data);

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch drivers.
   * @async
   * @function drivers
   * @param {Object} context - Vuex action context.
   * @param {string} orderId - The id of the order.
   */
  async drivers({ commit }, orderId) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/info/drivers?order_id=${orderId}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDrivers', respo.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch order statuses.
   * @async
   * @function statues
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the order.
   */
  async statues({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/get-statues/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setStatues', respo.data.data);
          console.log(respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update order status.
   * @async
   * @function updateStatus
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the new status.
   */
  async updateStatus({ commit }, data) {

    commit('loadingStart', null, { root: true });
    commit('updateData', data);

    commit('loadingFinish', null, { root: true });
  },

  /**
   * Cancel an order.
   * @async
   * @function cancel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the order to cancel.
   */
  async cancel({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/cancel/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Accept an order.
   * @async
   * @function accept
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the order.
   */
  async accept({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/accept/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch addresses for an order.
   * @async
   * @function getAddresses
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the order.
   */
  async getAddresses({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/get-addresses/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setAddresses', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vendor products for an order.
   * @async
   * @function getVendorProducts
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the order.
   */
  async getVendorProducts({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/vendor-products/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setProducts', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },
  async getOrderTypes({ commit }) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/info/order-types`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setOrderTypes', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vehicle types.
   * @async
   * @function getVehicleTypes
   * @param {Object} context - Vuex action context.
   */
  async getVehicleTypes({ commit }) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/info/vehicle-types`)
      .then(respo => {
        if (respo.status === 200) {

          commit('setVehicleTypes', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch product details.
   * @async
   * @function productDetail
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the product.
   */
  async productDetail({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`orders/product-detail/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('vendor/packages/setProductDetail', respo.data.data, { root: true });
          commit('setProductDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update user address for an order.
   * @async
   * @function updateUserAddress
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the new address.
   */
  async updateUserAddress({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/update-address/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update prices for an order.
   * @async
   * @function updatePrices
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the new prices.
   */
  async updatePrices({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/update-prices/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Assign a driver to an order.
   * @async
   * @function assignDriver
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the driver information.
   */
  async assignDriver({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/assign-driver`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggle driver for an order.
   * @async
   * @function toggleDriver
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the driver information.
   */
  async toggleDriver({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/toggle-driver`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Remove an item from the cart.
   * @async
   * @function removeFromCart
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the item to remove.
   */
  async removeFromCart({ commit }, data) {
    commit('removeProductFromOrder', data);
    commit('removeProductFromCart', data);
  },

  /**
   * Update the cart.
   * @async
   * @function updateCart
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the updated cart.
   */
  async updateCart({ commit }, data) {
    commit('updateProductOrder', data);
    commit('updateCart', data);
  },

  /**
   * Unset the cart.
   * @async
   * @function unsetCart
   * @param {Object} context - Vuex action context.
   */
  async unsetCart({ commit }) {
    // commit('unsetProductOrder');
    commit('unsetCart');
  },

  /**
   * Save changes to an order.
   * @async
   * @function saveOrderChanges
   * @param {Object} context - Vuex action context.
   * @param {string} id - The id of the order.
   */
  async saveOrderChanges({ commit, getters }, id) {
    commit('loadingStart', null, { root: true });
    let cart = getters['getCart'];
    let deleted = getters['getDeleteOrders'];
    await this._vm.$http
      .post(`orders/update-order/${id}`, { cart: cart, delete: deleted })
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Change the status of an order.
   * @async
   * @function changeOrderStatus
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the new status.
   */
  async changeOrderStatus({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .post(`orders/update-order-status/${data.id}`, { status: data.status })
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update an order.
   * @async
   * @function updateOrder
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the updated order.
   */
  async updateOrder({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`orders/update-order/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateOrderDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
