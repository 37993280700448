export default {
    getUsers(state) {
        return state.users;
    },
    getStatement(state) {
        return state.statement;
    },


    getStatementAccounting(state) {
        return state.statement.accounting;
    },
    getTotal(state) {
        return state.statement.total;
    },

    getFilters(state) {
        return state.filters;
    }
};
