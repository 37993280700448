export default {
  /**
   * Fetches service details from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`services-detail${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the state of a service detail on the server.
   * If the request is successful, it updates the state of the service detail in the store, shows a success message, and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`services-detail/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the detail of a service detail from the server and commits it to the store.
   * If the request is successful, it sets the detail data and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   * @returns {Object} - The detail data of the service.
   */
  async detail({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`services-detail/detail/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('setDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });
          return respo.data.data;
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates a service detail on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`services-detail/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
