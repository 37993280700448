import middleware from '../middleware/middleware';
import {permissions} from "@/enums/permissions.enum";

let ordersTypes = [
    "restaurants",
    "stores",
    "medicines",
    "delivery",
];
export default [


    {
        path: 'financial',
        component: {
            render(c) {
                return c('router-view');
            },
        },
        children: [


            ...ordersTypes.map((type) => {
                return {
                    path: type,
                    name: `financial-${type}`,
                    component: () => import('@/views/pages/financial/index.vue'),
                    meta: {
                        type: type,
                        middleware: [middleware],
                        // permissions: [
                        //     permissions[`manage${type[0].toUpperCase() + type.slice(1)}Orders`],
                        // ],
                        // pagePermissions: {
                        //     edit: permissions[`manage${type[0].toUpperCase() + type.slice(1)}Orders`],
                        //     read: permissions[`manage${type[0].toUpperCase() + type.slice(1)}Orders`],
                        // }
                    },
                }
            }),



        ],
    },

];
