// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {initializeAuth} from "firebase/auth";
import axios from "axios";

import store from '@/store'
import Vue from "vue";

/**
 * Firebase configuration object.
 * This is used to initialize Firebase with your specific project settings.
 * These settings can be found in your Firebase project settings.
 */
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIRE_BASE_API_KEY,
    authDomain: process.env.VUE_APP_FIRE_BASE_DOMAIN,
    projectId: process.env.VUE_APP_FIRE_BASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIRE_BASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIRE_BASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIRE_BASE_APP_ID,
    measurementId: process.env.VUE_APP_FIRE_BASE_MEASUREMENT_ID,
};

// Initialize Firebase with the configuration object
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
initializeAuth(app);

// Get a reference to Firebase Messaging
const messaging = getMessaging(app);

/**
 * Request a token for Firebase Cloud Messaging.
 * This token is used to send messages to this specific instance of the application.
 */
getToken(messaging, {vapidKey: process.env.VUE_APP_FIRE_BASE_VAPID_KEY}).then((currentToken) => {
    // Check if the user has granted the Notification permission
    if (Notification.permission === "granted") {
        if (currentToken) {
            // If a token is available, send it to the server
            axios.post(process.env.VUE_APP_AXIOS_URL + '/admin/notifications/fcm/register', {
                "platform": "web",
                "token": currentToken
            })
        }
    } else {
        // If the user has not granted the Notification permission, request it
        Notification.requestPermission().then((permission) => {
            // If the user grants the permission, send the token to the server
            if (permission === "granted") {
                if (currentToken) {
                    axios.post(process.env.VUE_APP_AXIOS_URL + '/admin/notifications/fcm/register', {
                        "platform": "web",
                        "token": currentToken
                    })
                }
            }
        });
    }
}).catch((err) => {
    // Log any errors that occur when trying to get the token
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

/**
 * Listen for messages from Firebase Cloud Messaging.
 * When a message is received, check if the user has granted the Notification permission.
 * If they have, handle the message.
 * If they have not, request the permission.
 */
onMessage(messaging, function (payload) {

    console.log( payload.data)

    // Check if the user has granted the Notification permission
    if (Notification.permission === "granted") {
        // If the message is a notification, update the notification limit
        if (payload.data.is_notification) {
            store.dispatch('admin/notifications/limit');
        } else {
            // If the message is not a notification, update the order status
            store.dispatch('vendor/orders/updateStatus', payload.data);
        }

    } else {
        // If the user has not granted the Notification permission, request it
        Notification.requestPermission().then((permission) => {
            // If the user grants the permission, handle the message
            if (permission === "granted") {
                // If the message is a notification, update the notification limit
                if (payload.data.is_notification) {
                    store.dispatch('admin/notifications/limit');
                } else {
                    // If the message is not a notification, update the order status
                    store.dispatch('vendor/orders/updateStatus', payload.data);
                }
            }

        });
    }

})