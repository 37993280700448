export default {
    vehicleTypes: [],
    vendorCategories: [],
    addressTypes: [],
    platformBoundaries: [],
    vendorClassification: [],
    data: [],
    total: {
        totalItems: 5,
        totalPages: 5,
    },

    simpleDetail: {},

};
