export default {
  newOrders: [],
  data: [],
  users: [],
  vendors:[],
  addresses: [],
  products: [],
  orderTypes: [],
  vehicleTypes: [],

  cart: [],
  deletedOrders: [],
  productDetail: {},
  priceDetail: {},

  detail: {
    driver: {
      id: 0
    }

  }

};
