/**
 * Vuex actions for managing user profile.
 * @module store/profile/actions
 */

export default {

  /**
   * Fetch basic profile data.
   * @async
   * @function basic
   * @param {Object} context - Vuex action context.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async basic({ commit, dispatch }) {
    // commit('loadingStart', null, {root: true});
    return await this._vm.$http
      .get(`profile`)
      .then((response) => {
        if (response.status === 200) {
          commit('setBasicData', response.data.data);

          //dispatch 'chat/getRooms'
          dispatch('chat/getRooms', null, { root: true });
          // commit('loadingFinish', null, {root: true});
        }
        return response.data.data;
      })
      .catch((error) => {
        // commit('loadingFinish', null, {root: true});
        throw error;
      });

  },

  /**
   * Fetch full profile data.
   * @async
   * @function full
   * @param {Object} context - Vuex action context.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async full({ commit }) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`profile/full`)
      .then((response) => {
        if (response.status === 200) {
          commit('setFullData', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update profile data.
   * @async
   * @function update
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the profile to update.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`profile/update`, data)
      .then((response) => {
        if (response.status === 200) {
          commit('loadingFinish', null, { root: true });
          commit('successMessage', 'contacts_changed');
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
