export default {

  /**
   * Fetches notifications from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} data - The data to send with the request.
   */
  async get({ commit }, data = '') {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get(`notifications/my?per_page=${data}`)
      .then((response) => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches users from the server and commits it to the store.
   * If the request is successful, it commits the data to the store.
   * If the request fails, it throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getUsers({ commit }) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`notifications/get-users`)
      .then((response) => {
        if (response.status === 200) {
          commit('setUsers', response.data.data);
        }
      }).catch((error) => {
        throw error;
      });
    commit('loadingFinish', null, { root: true });

  },

  /**
   * Fetches a limited number of notifications from the server and commits it to the store.
   * If the request is successful, it commits the data to the store.
   * If the request fails, it throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async limit({ commit }) {
    await this._vm.$http
      .get(`notifications/my?per_page=5`)
      .then((response) => {
        if (response.status === 200) {
          commit('setLimit', response.data.data);
        }
      }).catch((error) => {
        throw error;
      });
  },

  /**
   * Sends a notification to the server.
   * If the request is successful, it shows a success message and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async send({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post('notifications/send', data)
      .then((response) => {
        if (response.status === 200) {

          commit('successMessage', 'send_notification', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates a notification on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`notifications/update/${data.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Marks all notifications as read on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async markAsRead({ commit }) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post('notifications/mark-read')
      .then((response) => {
        if (response.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
