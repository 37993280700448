export default {
  /**
   * Fetches reports from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);
    await this._vm.$http
      .get(`reports${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          if (data.resetVendors) {
            commit('unsetVendors');
          }
          if (data.resetDrivers) {
            commit('unsetDrivers');
          }
          setTimeout(() => {
            commit('setData', respo.data.data);
          }, 0);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Unsets vendors in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async unsetVendors({ commit }) {
    commit('unsetVendors');
  },

  /**
   * Fetches vendors from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} service - The service to fetch vendors for.
   */
  async getVendors({ commit }, service) {
    commit('loadingStart', null, { root: true });
    let url = `reports/get-vendors`;
    if (service) {
      url = `reports/get-vendors?service=${service}`;
      commit('setTopClientsVendor', []);

    }
    await this._vm.$http
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          if (service) {
            commit('setTopClientsVendor', response.data.data);
          } else {
            commit('setVendorsList', response.data.data);
          }

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches services from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async getServices({ commit }) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .get('reports/get-services')
      .then((response) => {
        if (response.status === 200) {
          commit('setServicesList', response.data.data);
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
