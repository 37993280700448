/**
 * Vuex actions for managing utilities.
 * @module store/utilities/actions
 */
export default {

  /**
   * Upload files.
   * @async
   * @function uploadFiles
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing files to upload.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async uploadFiles({ commit }, data) {

    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`upload-files`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((respo) => {
        commit('loadingFinish', null, { root: true });
        if (respo.status === 200) {
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Get vendor categories.
   * @async
   * @function getVendorCategories
   * @param {Object} context - Vuex action context.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async getVendorCategories({ commit }) {

    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`vendor-categories`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setVendorCategories', respo.data.data);
        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Get vendors dropdown based on category id.
   * @async
   * @function getVendorsDropdown
   * @param {Object} context - Vuex action context.
   * @param {string} id - Category id.
   * @returns {Promise} Promise representing the HTTP request.
   */
  async getVendorsDropdown({ commit }, id) {

    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`vendors-on-category?category_id=${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setVendorsOnCategory', respo.data.data);
        }

        commit('loadingFinish', null, { root: true });
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
