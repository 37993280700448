/**
 * Vuex actions for managing financials.
 * @module store/vendor/financial/actions
 */
export default {
  /**
   * Fetch financials based on filters.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for fetching financials.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);
    await this._vm.$http
      .get(`financials${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
