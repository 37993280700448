export default {

  /**
   * Fetches page settings from the server and commits it to the store.
   * If the request is successful, it commits the data to the store and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} page - The name of the page to fetch settings for.
   * @returns {Promise} - The promise of the HTTP request.
   */
  async get({ commit }, page) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .get(`pages-settings/get/${page}`)
      .then((response) => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates page settings on the server.
   * If the request is successful, it shows a success message and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   * @returns {Promise} - The promise of the HTTP request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`pages-settings/update`, data)
      .then((response) => {
        if (response.status === 200) {


          commit('successMessage', 'save', { root: true });
          commit('loadingFinish', null, { root: true });

        }
      })
      .catch((error) => {

        commit('failMessage', 'save', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
