export default {
    setData: (state, data) => {
        state.data = data.content;
        state.total = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },

    setFinancials: (state, data) => {
        state.financials = data.content;
        state.totalFinancials = {
            totalItems: data.total,
            totalPages: data.last_page,

            total_orders: data.total_orders,
            his_profit: data.his_profit,
            our_profit: data.our_profit,
            total_sales: data.total_sales,

        };
    },

    setReviews: (state, data) => {
        state.reviews = data.content;
        state.totalReviews = {
            totalItems: data.total,
            totalPages: data.last_page,
            rateAverage: data.rate_avg,
            rates: data.rates,
        };
    },

    unsetData: (state) => {
        state.data = [];
        state.total = {
            totalItems: 0,
            totalPages: 0,
        };
    },


    setUserInfo: (state, data) => {
        state.userInfo = data;
    },

    updateUserInfo: (state, data) => {
        if (state.userInfo) {
            console.log(state.userInfo)
            if (state.userInfo.id == data.id) {
                state.userInfo = {...state.userInfo, status: data.status};
            }
        }
    },
    unsetDetail: (state) => {
        state.detail = {};
    },

};
