export default {
  /**
   * Fetches payment methods from the server and commits it to the store.
   * If the request is successful, it unsets the previous payment methods, sets the new data, and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`payment-methods${filters}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('unsetPayment');
          setTimeout(() => {
            commit('setData', respo.data.data);
          }, 0);


          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Reorders payment methods on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async reorder({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`payment-methods/reorder/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggles the state of a payment method on the server.
   * If the request is successful, it updates the state of the payment method in the store, shows a success message, and stops the loading state.
   * If the request fails, it shows a failure message, stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`payment-methods/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches the detail of a payment method from the server and commits it to the store.
   * If the request is successful, it sets the detail data and stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async detail({ commit }, data) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`payment-methods/detail/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('setDetail', respo.data.data);
          commit('loadingFinish', null, { root: true });
          return respo.data.data;
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Updates a payment method on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`payment-methods/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Creates a new payment method on the server.
   * If the request is successful, it stops the loading state.
   * If the request fails, it stops the loading state and throws an error.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`payment-methods/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
