/**
 * Vuex actions for managing vendor categories.
 * @module store/vendor/categories/actions
 */
export default {
  /**
   * Fetch vendor categories based on filters.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing filters for fetching vendor categories.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`users/vendors/${data.vendor_id}/categories${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('unsetCategories');
          setTimeout(() => {
            commit('setData', respo.data.data);
          }, 0);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Reorder vendor categories.
   * @async
   * @function reorder
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing the order of vendor categories.
   */
  async reorder({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/categories/reorder/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Toggle vendor category status.
   * @async
   * @function toggle
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the vendor category to toggle.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/categories/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateStatusData', respo.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Create a new vendor category.
   * @async
   * @function create
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the new vendor category.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/categories/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update a vendor category.
   * @async
   * @function update
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the vendor category to update.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/categories/update`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        if (error.response.status === 402) {
          commit('failMessage', 'category_update', { root: true });
        }

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Delete a vendor category.
   * @async
   * @function delete
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the vendor category to delete.
   */
  async delete({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/vendors/${data.vendor_id}/categories/delete/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'delete', { root: true });
          commit('loadingFinish', null, { root: true });

          commit('deleteItem', data.id);
        }
      })
      .catch(error => {
        if (error.response.status === 402) {
          commit('failMessage', 'category_delete', { root: true });
        }
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }
};
