// import Vue from 'vue'

// // Lib imports
// import axios from 'axios'

// Vue.prototype.$http = axios
// s
import store from '@/store/index';
import Vue from 'vue';
import axios from 'axios';

// Assign axios to Vue prototype
Vue.prototype.$http = axios;

// Set the base URL for axios requests
Vue.prototype.$http.defaults.baseURL = `${process.env.VUE_APP_AXIOS_URL}/admin/`;

// Set the default 'Content-Type' for POST requests
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json;  charset=UTF-8';

/**
 * Axios request interceptor
 * This will be triggered before every axios request
 * It sets the locale and Authorization headers
 */
Vue.prototype.$http.interceptors.request.use(request => {
    // Set the locale header from the store
    request.headers["locale"] = store.getters['getCurrentLocale'];

    // Update token axios header
    const authToken = request.headers.common.Authorization;
    if (!authToken) {
        const loginData = Vue.$cookies.get('login-data', {parseJSON: true});
        if (loginData) {
            request.headers.common.Authorization = `Bearer ${loginData.access_token}`;
        }
    }
    return request;
});

/**
 * Axios response interceptor
 * This will be triggered after every axios response
 * It handles different response status codes
 */
Vue.prototype.$http.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 400) {
            store.commit(
                'failMessage',
              {
                code:error.response.data.code ? error.response.data.code : 'error',
                message:error.response.data.message ? error.response.data.message : 'error'
              },
            );
        } else if (error.response.status === 401) {
            const loginData = Vue.$cookies.get('login-data', {parseJSON: true});
            if (loginData) {
                store.dispatch('auth/logout');
                store.commit('failMessage', error.response.status);
            } else {
                store.dispatch('auth/logout');
            }
        } else if (
            error.response.status === 404
            || error.response.status === 422
            || error.response.status === 403
            || error.response.status === 500
        ) {
            store.commit('failMessage', error.response.status);
        }
        return Promise.reject(error);
    },
);
