import categories from './categories';
import products from './products';
import packages from './packages';
import financial from './financial';
import orders from './orders';
import workDays from './workDays';


export default {
  modules: {
    products,
    packages,
    categories,
    workDays,
    orders,
    financial
  },
  namespaced: true
};
