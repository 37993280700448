import i18n from '@/libs/i18n';
import router from '@/router';

/**
 * Vuex actions for managing users.
 * @module store/admin/users/actions
 */
export default {
  /**
   * Fetch users based on type and filters.
   * @async
   * @function get
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing type and filters for fetching users.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`users/${data.type}${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setData', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Unset user data.
   * @async
   * @function unsetData
   * @param {Object} context - Vuex action context.
   */
  async unsetData({ commit }) {
    commit('unsetData');
  },

  /**
   * Export user data to Excel.
   * @async
   * @function exportExcel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing type for exporting users.
   */
  async exportExcel({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .get(`users/${data.type}/export-excel`, { responseType: 'blob' })
      .then(respo => {
        if (respo.status === 200) {
          // commit('setData', respo.data.data);
          const url = window.URL.createObjectURL(new Blob([respo.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', data.type + '.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Import user data from Excel.
   * @async
   * @function importExcel
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing type for importing users.
   */
  async importExcel({ commit, dispatch }, data) {
    commit('loadingStart', null, { root: true });


    return await this._vm.$http
      .post(`users/${data.type}/import-excel`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
          return true;
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch simple detail of a user.
   * @async
   * @function simpleDetail
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data containing type and id for fetching user detail.
   */
  async simpleDetail({ commit }, data) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`users/${data.type}/simple/${data.id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDetail', respo.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },
  /**
   * Unset user detail.
   * @async
   * @function unsetDetail
   * @param {Object} context - Vuex action context.
   */
  async unsetDetail({ commit }) {
    commit('unsetDetail');
  },

  /**
   * Toggle user status.
   * @async
   * @function toggle
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user to toggle.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    return await this._vm.$http
      .post(`users/toggle`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('updateData', respo.data.data);

          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
          return respo.data.data;
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Create a new user.
   * @async
   * @function create
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the new user.
   */
  async create({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/${data.type}/create`, data)
      .then(respo => {
        if (respo.status === 200) {
          if (data.type !== 'vendors') {
            router.push({ name: `users-${data.type}`, params: { lang: i18n.locale } });
          }
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Update a user.
   * @async
   * @function update
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the user to update.
   */
  async update({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/${data.type}/update/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: `users-${data.type}`, params: { lang: i18n.locale } });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vehicle types for drivers.
   * @async
   * @function getVehicleTypes
   * @param {Object} context - Vuex action context.
   */
  async getVehicleTypes({ commit }) {

    await this._vm.$http
      .get(`users/drivers/vehicle-types`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setVehicleTypes', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch address types for clients.
   * @async
   * @function getAddressTypes
   * @param {Object} context - Vuex action context.
   */
  async getAddressTypes({ commit }) {

    await this._vm.$http
      .get(`users/clients/address-types`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setAddressTypes', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Create a new address for a client.
   * @async
   * @function createAddress
   * @param {Object} context - Vuex action context.
   * @param {Object} data - Data for the new address.
   */
  async createAddress({ commit }, data) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`users/clients/store-address`, data)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Fetch vendor categories.
   * @async
   * @function getVendorCategories
   * @param {Object} context - Vuex action context.
   */
  async getVendorCategories({ commit }) {

    await this._vm.$http
      .get(`users/vendors/categories`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setVendorCategories', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetch vendor classification based on id.
   * @async
   * @function getVendorClassification
   * @param {Object} context - Vuex action context.
   * @param {string} id - ID of the vendor classification to fetch.
   */
  async getVendorClassification({ commit }, id) {

    await this._vm.$http
      .get(`users/vendors/classifications/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setVendorClassification', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Reset vendor password based on id.
   * @async
   * @function resetVendorPassword
   * @param {Object} context - Vuex action context.
   * @param {string} id - ID of the vendor to reset password.
   */
  async resetVendorPassword({ commit }, id) {
    commit('loadingStart', null, { root: true });
    return await this._vm.$http
      .post(`users/vendors/reset-password/${id}`)
      .then(respo => {
        if (respo.status === 200) {

          commit('loadingFinish', null, { root: true });
        }
        return respo.data.data;
      })
      .catch(error => {

        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  // platformBoundaries
  async getPlatformBoundaries({ commit }) {
    await this._vm.$http
      .get('users/vendors/get-platform-boundary')
      .then(respo => {
        if (respo.status === 200) {
          commit('setPlatformBoundaries', respo.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }

};
