export default {
  /**
   * Fetches vendor discounts data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async get({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`discounts/vendor${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setData', response.data.data);

          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Fetches detailed discount data for a specific vendor discount from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor discount to fetch.
   */
  async detail({ commit }, id) {
    commit('loadingStart', null, { root: true });

    await this._vm.$http
      .get(`discounts/vendor/${id}`)
      .then(response => {
        if (response.status === 200) {
          commit('setDetail', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },


  /**
   * Resets the products data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async resetProducts({ commit }) {

    commit('setProducts', null);

  },

  /**
   * Fetches discount info for a specific vendor discount from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor discount to fetch info for.
   */
  async discountInfo({ commit }, id) {
    commit('loadingStart', null, { root: true });
    commit('unsetDiscountInfo');

    await this._vm.$http
      .get(`discounts/vendor/${id}/info`)
      .then(response => {
        if (response.status === 200) {
          commit('setDiscountInfo', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Fetches discount usage data from the server and commits it to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async discountUsage({ commit }, data) {
    commit('loadingStart', null, { root: true });

    const filters = window.mapFilterData(data);

    await this._vm.$http
      .get(`discounts/vendor/${data.id}/usage${filters}`)
      .then(response => {
        if (response.status === 200) {
          commit('setDiscountUsage', response.data.data);

          commit('loadingFinish', null, { root: true });

        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Unsets the detail data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   */
  async unsetDetail({ commit }) {
    commit('unsetDetail');
  },

  /**
   * Toggles the status of a vendor discount on the server and commits the updated vendor discount data to the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {Object} data - The data to send with the request.
   */
  async toggle({ commit }, data) {
    commit('loadingStart', null, { root: true });


    await this._vm.$http
      .post(`discounts/vendor/toggle`, data)
      .then(response => {
        if (response.status === 200) {

          commit('updateData', response.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('failMessage', 'toggle', { root: true });
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Rejects a vendor discount on the server and updates the vendor discount data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor discount to reject.
   */
  async reject({ commit }, id) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`discounts/vendor/reject/${id}`)
      .then(response => {
        if (response.status === 200) {

          commit('updateData', response.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  },

  /**
   * Approves a vendor discount on the server and updates the vendor discount data in the store.
   *
   * @param {Function} commit - Vuex commit function.
   * @param {string} id - The ID of the vendor discount to approve.
   */
  async approve({ commit }, id) {
    commit('loadingStart', null, { root: true });
    await this._vm.$http
      .post(`discounts/vendor/approve/${id}`)
      .then(response => {
        if (response.status === 200) {

          commit('updateData', response.data.data);
          commit('successMessage', 'toggle', { root: true });
          commit('loadingFinish', null, { root: true });
        }
      })
      .catch(error => {
        commit('loadingFinish', null, { root: true });
        throw error;
      });
  }


};
